import {post, get} from "@/utils/request";

export const login = (formdata) => {
    return post('login', formdata)
}
export const activityAdd = (formdata) => {
    return post('activity/edit', formdata)
}
export const activityDetail = () => {
    return get('activity/detail')
}
export const bgmList = (params) => {
    return get('background/list', params)
}
export const deleteBgImage = (id) => {
    return post('background/del', {background_id: id})
}
export const bgimageInfo = (id) => {
    return get('background/detail', {background_id: id})
}
export const bgimageAdd = (formdata) => {
    return post('background/add', formdata)
}
export const bgimageChange = (id) => {
    return post('background/open', {background_id: id})
}
export const giftList = (params) => {
    return get('gift/list', params)
}
export const giftAdd = (formdata) => {
    return post('gift/add', formdata)
}
export const giftDo = (formdata) => {
    return post('gift/do', formdata)
}
export const giftDel = (id) => {
    return post('gift/del', {gift_id: id})
}
export const uploadGiftImg = (formdata) => {
    return post('gift/giftImg', formdata)
}
export const giftInfo = (id) => {
    return get('gift/detail', {gift_id: id})
}

export const pointsList = (params) => {
    return get('accountAdmin/accountIntegralLog', params)
}

export const userSortList = (params) => {
    return get('frontData/accountRankList', params)
}
export const LogsData = (params) => {
    return get('accountAdmin/accountGiftLog', params)
}
export const takeGift = (id) => {
    return post('accountAdmin/checkAccountGift', {account_gift_id: id})
}
export const webIndex = () => {
    return get('frontData/webData')
}
export const webNotice = () => {
    return get('frontData/pushData')
}
export const wordsList = (params) => {
    return get('words/list', params)
}
export const wordAdd = (formdata) => {
    return post('words/add', formdata)
}
export const wordDel = (formdata) => {
    return post('words/del', formdata)
}
export const sayLists = (params) => {
    return get('accountAdmin/accountMessageList', params)
}
export const disabledWords = (params) => {
    return get('accountAdmin/doAccountMessage', params)
}
export const wordloop = () => {
    return get('frontData/getMessage')
}