import axios from "axios";

const instance = axios.create({
    timeout: 3000
})

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    config.headers.authorization = token
    return config
}, (error) => {
    return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
    const res = response.data
    if (res.code === 200) {
        return {data: res.data, msg: res.msg}
        Promise.resolve()
    } else {
        return Promise.reject(new Error(res.msg))
    }
}, (error) => {
    console.log('err', error);
    return Promise.reject(error)
})

export const post = (url,formdata) => {
    return instance.post(url, formdata)
}
export const get = (url, params) => {
    return instance.get(url, {params: params})
}
